// prefer default export if available

const preferDefault = m => m && m.default || m
exports.components = {
  "component---src-views-components-hero-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/593a280b-9ba0-5b12-bd65-17582adce90a.js" /* webpackChunkName: "component---src-views-components-hero-index-tsx" */).then(preferDefault),
  "component---src-views-components-features-list-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/181e6651-c48e-5003-b9c2-60b693439dad.js" /* webpackChunkName: "component---src-views-components-features-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-card-slider-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/61cc61d7-e210-5e77-8ed6-67b325561811.js" /* webpackChunkName: "component---src-views-components-card-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-infographics-with-phases-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/9a5c82e0-bcf5-51b3-8759-bb9c92e48bdb.js" /* webpackChunkName: "component---src-views-components-infographics-with-phases-index-tsx" */).then(preferDefault),
  "component---src-views-components-half-content-half-widget-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/cec0e1dc-823d-5f6b-8233-f2c9d20cc848.js" /* webpackChunkName: "component---src-views-components-half-content-half-widget-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-menu-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/4e59f872-8f40-52d7-9485-3c254818434c.js" /* webpackChunkName: "component---src-views-components-icon-menu-index-tsx" */).then(preferDefault),
  "component---src-views-components-toggable-content-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/5fd0562c-6a21-5312-b494-cb43ee3fce1b.js" /* webpackChunkName: "component---src-views-components-toggable-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-simple-card-list-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/3ff79d3a-3d8f-5ef7-a657-60f8bcb7049b.js" /* webpackChunkName: "component---src-views-components-simple-card-list-index-tsx" */).then(preferDefault),
  "component---src-views-components-textual-content-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/8efc8355-4c6c-5098-b1fa-c3630afdd675.js" /* webpackChunkName: "component---src-views-components-textual-content-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/af4a161f-5424-5874-ad50-8b29f5d3ba95.js" /* webpackChunkName: "component---src-views-components-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-columns-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/16ee865d-96b9-5cbc-8dfa-dd30186eefd3.js" /* webpackChunkName: "component---src-views-components-columns-index-tsx" */).then(preferDefault),
  "component---src-views-components-icon-box-grid-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/bacb8ed4-2c7a-512b-95f6-8a4d7c96fdd7.js" /* webpackChunkName: "component---src-views-components-icon-box-grid-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-text-box-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/2c483810-e7a4-53e7-a21d-10f8db1632cd.js" /* webpackChunkName: "component---src-views-components-media-text-box-index-tsx" */).then(preferDefault),
  "component---src-views-components-timeline-with-images-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/216cfcb9-6d92-55ea-871c-d047815720cf.js" /* webpackChunkName: "component---src-views-components-timeline-with-images-index-tsx" */).then(preferDefault),
  "component---src-views-components-our-team-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/408c35df-8db8-5f40-bbd2-ae0a1b8b7834.js" /* webpackChunkName: "component---src-views-components-our-team-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-section-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/7030cf35-5a75-5c9d-b7d3-406a8a311d3f.js" /* webpackChunkName: "component---src-views-components-faq-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-faq-list-section-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/1fb59c51-d307-5475-a66a-0ab69cfd84db.js" /* webpackChunkName: "component---src-views-components-faq-list-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-vertical-scroll-slides-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/f24eb158-4f1d-57eb-9c7d-7edface5a9c7.js" /* webpackChunkName: "component---src-views-components-vertical-scroll-slides-index-tsx" */).then(preferDefault),
  "component---src-views-components-form-with-media-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/dd979fe4-7640-50a7-8562-a74778a5db14.js" /* webpackChunkName: "component---src-views-components-form-with-media-index-tsx" */).then(preferDefault),
  "component---src-views-components-help-center-flow-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/8b7cae0d-4310-5e1d-980f-90be5040713c.js" /* webpackChunkName: "component---src-views-components-help-center-flow-index-tsx" */).then(preferDefault),
  "component---src-views-components-video-section-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/aafa8197-7b0c-5243-9135-300a1c1c91aa.js" /* webpackChunkName: "component---src-views-components-video-section-index-tsx" */).then(preferDefault),
  "component---src-views-components-media-slider-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/c5ab7bde-f764-5fff-adec-64c835e315bc.js" /* webpackChunkName: "component---src-views-components-media-slider-index-tsx" */).then(preferDefault),
  "component---src-views-components-job-listing-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/6495593f-7081-569e-9cd9-549559f7919b.js" /* webpackChunkName: "component---src-views-components-job-listing-index-tsx" */).then(preferDefault),
  "component---src-views-components-track-shipment-results-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/4253eb04-d17d-5ac5-8ff2-d46d309ef861.js" /* webpackChunkName: "component---src-views-components-track-shipment-results-index-tsx" */).then(preferDefault),
  "component---src-views-components-faqs-contact-details-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/f55a0d06-5ed4-5589-9982-230dff00c563.js" /* webpackChunkName: "component---src-views-components-faqs-contact-details-index-tsx" */).then(preferDefault),
  "component---src-views-components-sitemap-index-tsx": () => import("/codebuild/output/src3161326166/src/.cache/gatsby-plugin-graphql-component/components/e36450bb-d624-5adf-ab72-384837c96611.js" /* webpackChunkName: "component---src-views-components-sitemap-index-tsx" */).then(preferDefault)
}

