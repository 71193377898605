import { AppMiddleware } from '../middleware';
import control from './control/middleware';
import header from './header/middleware';
import analytics from './analytics/middleware';

export const createCoreMiddleware = (): AppMiddleware[] => ([
  ...control,
  ...header,
  ...analytics,
]);
